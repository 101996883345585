import React, { createContext, useContext } from 'react'
import { useState } from 'react';
const mobileMenu = createContext();



export default function HeaderContext({ children }) {
    const [isSidebarHidden, setSidebarHidden] = useState(false);
    const [isOutletFullWidth, setOutletFullWidth] = useState(false);

    const contextValues = {
        isSidebarHidden,
        setSidebarHidden,
        isOutletFullWidth,
        setOutletFullWidth
    };
    return (
        <mobileMenu.Provider value={contextValues}>
            {children}
        </mobileMenu.Provider>
    )
}

const MenuProvider = () => {
    return useContext(mobileMenu)
}

export { MenuProvider }
