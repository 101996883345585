import Card from './Component/Card';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import moment from 'moment';
export default function InactiveSubscriber() {

    let token = localStorage.getItem('token');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState('')
    const [tcount, setTcount] = useState('')
    const [network, setNetwork] = useState('')
    const [number, setNumber] = useState('')

    // Convert the string to a Date object
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);


    let startYear = startDateObj.getFullYear();
    let startMonth = ('0' + (startDateObj.getMonth() + 1)).slice(-2);
    let startDates = ('0' + startDateObj.getDate()).slice(-2);

    let endYear = endDateObj.getFullYear();
    let endMonth = ('0' + (endDateObj.getMonth() + 1)).slice(-2);
    let endDates = ('0' + endDateObj.getDate()).slice(-2);

    let startFormattedDate = `${startYear}-${startMonth}-${startDates}`;
    let endFormattedDate = `${endYear}-${endMonth}-${endDates}`;

    const handleNetworkChange = (event) => {
        setNetwork(event.target.value);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let apiUrl = `${baseUrl}/user/inactive_subscribers?`;

                if (startDate && endDate) {
                    apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
                }
                apiUrl += `&mobile=${number}`;
                if (network !== 'all') {
                    apiUrl += `&network=${network}`;
                }

                const response = await axios.get(
                    apiUrl,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const modifiedData = response.data.data.map((item, i) => {
                    return {
                        ...item,
                        start_date: moment(item.start_date && item.start_date.split('T')[0]).format('DD/MM/YYYY'),
                        id: i + 1,
                        user_name: item.userDetail.username
                    }
                });




                setTcount(modifiedData.length)
                setData(modifiedData);
            } catch (error) {
                console.log(error, "api does not work");
            }
        };

        fetchData();
    }, [startDate, endDate, network, number]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 20,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'start_date',
            headerName: 'Registration Date',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'

        },
        {
            field: 'mobile_number',
            headerName: 'Mobile Number',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'


        }, {
            field: 'network',
            headerName: 'Network',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'


        }, {
            field: 'user_name',
            headerName: 'User Name',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
    ];

    const handleDownload = async () => {
        try {
            let apiUrl = `${baseUrl}/user/export-file-data/inactive-subscribers?`;

            if (startDate && endDate) {
                apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
            }

            if (network !== 'all') {
                apiUrl += `&network=${network}`;
            }

            apiUrl += `&mobile=${number}`;

            const response = await axios.get(apiUrl, { responseType: 'blob' });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Inactive Subscribers ${moment().format('DD-MM-YYYY')} .csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error, "API does not work");
        }
    }

    function CustomToolbar() {
        return (
            <button onClick={handleDownload} className='btn btn-dark'>Export</button>
        );
    }
    return (
        <div className='myContainer'>
            <div className='innerContainer'>
                <div className='containerCard'>
                    <div className="col-md-3">
                        <Card
                            title={"Inactive Subscribers"}
                            number={tcount ? tcount : '0'}
                            className='myCard indiviusalCard'
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='filter'>
                        <div className='filterByDate date_number'>
                            <div>
                                <div className='title'>
                                    <h6>
                                        Filter By Date:
                                    </h6>
                                </div>
                                <div className='datePicker'>
                                    <div>
                                        <p className='m-0'>Start Date</p>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat='dd/MM/yyyy'
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div>
                                        <p className='m-0'>End Date</p>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat='dd/MM/yyyy'
                                            maxDate={new Date()}
                                            minDate={startDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-between'>
                                <div className='title'>
                                    <h6>
                                        Filter By Phone Number:
                                    </h6>
                                </div>
                                <div className='mobilePicker'>

                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="mobile number" aria-label="Username" onChange={(e) => setNumber(e.target.value)} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='filterByNetwork py-3'>
                            <div className='title'>
                                <h6>Filter By Network:</h6>
                            </div>
                            <div className='networkPicker'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="network" id="allNetwork" value="all" onChange={handleNetworkChange} checked={network === 'all'} />
                                    <label className="form-check-label" htmlFor="allNetwork">All</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="network" id="mtnNetwork" value="mtn" onChange={handleNetworkChange} checked={network === 'mtn'} />
                                    <label className="form-check-label" htmlFor="mtnNetwork">MTN</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="network" id="airtelNetwork" value="airtel" onChange={handleNetworkChange} checked={network === 'airtel'} />
                                    <label className="form-check-label" htmlFor="airtelNetwork">Airtel</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="network" id="gloNetwork" value="glo" onChange={handleNetworkChange} checked={network === 'glo'} />
                                    <label className="form-check-label" htmlFor="gloNetwork">Glo</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="network" id="etisalatNetwork" value="9mobile" onChange={handleNetworkChange} checked={network === '9mobile'} />
                                    <label className="form-check-label" htmlFor="etisalatNetwork">9mobile</label>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <div className="datagrid containerDataGrid">
                    <h5>Inactive Subscribers</h5>

                    <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                rowsPerPageOptions={[10]}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}

                            />
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}
