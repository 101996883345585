import React, { useEffect, useState } from "react";
import Card from './Component/Card';
import Toggle from 'react-toggle';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import "react-toggle/style.css";
import moment, { duration } from "moment";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormData from "form-data";
import { TrySharp } from "@mui/icons-material";
import Notiflix from "notiflix";

export default function AllUsers() {
    let token = localStorage.getItem('token');

    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [count, setCount] = useState('');
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [number, setNumber] = useState('');
    function CustomToolbar() {
        return (
            <GridToolbarExport csvOptions={{
                fileName: `User Account ${moment().format('DD-MM-YYYY')}`,
            }} />
        );
    }

    const updateStatus = async (id, currentActive) => {
        try {

            console.log({ id, currentActive });

            const response = await axios.post(`${baseUrl}/user/block-user`, { user_id: id }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status == 200) {

                fetchAllUsers(page, size);

                toast.success(response.data.message, { duration: 3000 });

            }

        } catch (error) {
            console.error("Failed to update status:", error);
        }

    };


    const fetchAllUsers = async (page, size) => {
        try {

            Notiflix.Loading.circle();
            const response = await axios.get(`${baseUrl}/user/get-user-register?page=${page}&size=${size}&status=3&mobile=${number}`);

            console.log(response.data);

            if (response.status == 200) {

                setData(response.data.data.rows.map((item, i) => {
                    let obj = { ...item, index: i + 1 + ((page - 1) * size) };
                    return obj;

                }))
                setTotalRows(response.data.data.count);

            }

            Notiflix.Loading.remove();
        } catch (err) {
            Notiflix.Loading.remove();
            return err.message;
        }
    }



    useEffect(() => {

        const interval = setTimeout(() => {

            fetchAllUsers(page, size);

        }, 10);

        return () => clearInterval(interval);

    }, [page, size, number]);

    const columns = [
        { field: 'index', headerName: 'SN', width: 50, align: 'center', headerAlign: 'center' },
        { field: 'username', headerName: 'User Name', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'mobile', headerName: 'Mobile', flex: 1, align: 'center', headerAlign: 'center' },
        // { field: 'redeemed_spins', headerName: 'Redeemed Spins', flex: 1, align: 'center', headerAlign: 'center' },
        // { field: 'available_spins', headerName: 'Available Spins', flex: 1, align: 'center', headerAlign: 'center' },
        // { field: 'withdraw_amount', headerName: 'Withdrawn Amount', flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: 'status',
            headerName: 'Active',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Toggle
                    checked={params.row.status == 1}
                    icons={{
                        checked: <span className="custom-icon"></span>,
                        unchecked: <span className="custom-icon"></span>,
                    }}
                    onChange={() => updateStatus(params.row.id, params.row.status)}
                />
            ),
        },
    ];

    const formik = useFormik({
        initialValues: {
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            // Handle form submission here
            console.log(values);

            try {

                let formData = new FormData();
                formData.append("file", values.file);

                let res = await axios.post(`${baseUrl}/user/block`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                })

                if (res.status == 200) {
                    toast.success(res.data.message, { duration: 3000 })
                } else {
                    toast.info(res.data.message, { duration: 3000 })
                }

            } catch (err) {
                toast.error(err?.response?.data?.message || err?.message, { duration: 3000 })
            }


        },
    });

    return (
        <div className='myContainer'>
            <div className='innerContainer'>
                <div className='containerCard'>
                    <div className="col-md-3">
                        <Card
                            title={"User Accounts"}
                            number={count ? count : 0}
                            className='myCard indiviusalCard'
                        />
                    </div>
                </div>

                <div className='row mb-3 ml-auto'>
                    <div className='filter'>
                        <div className='filterByDate date_number'>
                            {/* <div>
                                <div className='title'>
                                    <h6>
                                        Filter By Date:
                                    </h6>
                                </div>
                                <div className='datePicker'>
                                    <div>
                                        <p className='m-0'>Start Date</p>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat='dd/MM/yyyy'
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div>
                                        <p className='m-0'>End Date</p>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat='dd/MM/yyyy'
                                            maxDate={new Date()}
                                            minDate={startDate}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className='d-flex flex-column justify-content-between ml-auto'>
                                <div className='title'>
                                    <h6>
                                        Filter By Phone Number:
                                    </h6>
                                </div>
                                <div className='mobilePicker'>

                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="mobile number" aria-label="Username" onChange={(e) => setNumber(e.target.value)} />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>


                <div className="mc-blacklist-form">
                    <div className="w-100 head">
                        <h3>
                            Block users
                        </h3>
                    </div>
                    <form
                        className="form-container"
                        onSubmit={formik.handleSubmit}>
                        <input
                            type="file"
                            name="file"
                            required
                            onChange={(event) => {
                                formik.setFieldValue('file', event.currentTarget.files[0]);
                            }}
                        />
                        {/* {formik.values.file && (
                            <div>
                                <p>Selected file: {formik.values.file.name}</p>
                                <button type="button" onClick={() => formik.setFieldValue('file', null)}>Remove file</button>
                            </div>
                        )} */}
                        <button type="submit" disabled={formik.isSubmitting}>Submit</button>
                    </form>

                </div>

                <div className="datagrid containerDataGrid">
                    <h5>Users Account</h5>
                    <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                paginationMode="server"
                                rows={data}
                                columns={columns}
                                rowCount={totalRows}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSize={size}
                                onPaginationModelChange={(page) => { setPage(page.page + 1); setSize(page.pageSize) }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
}
