import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { FiAlignJustify } from "react-icons/fi";
import { MenuProvider } from '../Context/HeaderContext';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
function Header() {

    const { setSidebarHidden, setOutletFullWidth, isSidebarHidden, isOutletFullWidth } = MenuProvider();
    const toggleMobileMenu = () => {
        setSidebarHidden(!isSidebarHidden);
        setOutletFullWidth(!isOutletFullWidth);
    };
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login')
    }
    return (
        <div className='header d-flex align-items-center justify-content-between'>


            <Nav as="ul" className="d-flex align-items-center ps-2">
                <Nav.Item as="li" className='mobileMenu' onClick={toggleMobileMenu}>
                    <FiAlignJustify className='mobileMenuIcon' />
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link href="/">
                        <Image src="logoimg.png" className='mylogo' />
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            <Nav as="ul" className="d-flex align-items-center pe-5">
                <Nav.Item as="li">
                    <Button variant="danger" className='fw-bold logoutBtn' onClick={handleLogout}>Logout</Button>{' '}
                </Nav.Item>
            </Nav>
        </div>

    );
}

export default Header;