import Card from './Component/Card';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import Notiflix from 'notiflix';
export default function UsedSpin() {
  let token = localStorage.getItem('token');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState('')
  const [number, setNumber] = useState('')
  const [network, setNetwork] = useState('')
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [tcount, setTcount] = useState('')
  const [usedSpin, setUsedSpin] = useState('')
  let startYear = startDateObj.getFullYear();
  let startMonth = ('0' + (startDateObj.getMonth() + 1)).slice(-2);
  let startDates = ('0' + startDateObj.getDate()).slice(-2);

  let endYear = endDateObj.getFullYear();
  let endMonth = ('0' + (endDateObj.getMonth() + 1)).slice(-2);
  let endDates = ('0' + endDateObj.getDate()).slice(-2);

  let startFormattedDate = `${startYear}-${startMonth}-${startDates}`;
  let endFormattedDate = `${endYear}-${endMonth}-${endDates}`;

  const handleNetworkChange = (event) => {
    setNetwork(event.target.value);
  }

  const handleDownload = async () => {
    try {
      let apiUrl = `${baseUrl}/user/export-file-data/used-spins?`;

      if (startDate && endDate) {
        apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
      }

      if (network !== 'all') {
        apiUrl += `&network=${network}`;
      }

      apiUrl += `&mobile=${number}`;

      const response = await axios.get(apiUrl, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Used Spins ${moment().format('DD-MM-YYYY')} .csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.log(error, "API does not work");
    }
  }

  function CustomToolbar() {
    return (
      <button onClick={handleDownload} className='btn btn-dark'>Export</button>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      Notiflix.Loading.pulse()
      try {
        let apiUrl = `${baseUrl}/user/used-spins?`;

        if (startDate && endDate) {
          apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
        }


        apiUrl += `&mobile=${number}`;
        apiUrl += `&page=${pagination.page}&limit=${pagination.pageSize}`;
        if (network !== 'all') {
          apiUrl += `&network=${network}`;
        }

        const response = await axios.get(
          apiUrl,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data.count, "response response")


        const modifiedData = response.data.data.rows.map((item, i) => {
          return {
            ...item,
            id: ((pagination.page - 1) * pagination.pageSize) + 1 + i,
            mobile: item.userDetail.mobile,
            network: item.userDetail.network,
            created_at_date: moment(item.created_at && item.created_at.split(' ')[0]).format('DD/MM/YYYY'),
            created_at_time: item.created_at && item.created_at.split(' ')[1].split('.')[0],
          }
        });

        const totalusedSpin = response.data.data.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        console.log(totalusedSpin, "totalusedSpin")
        setUsedSpin(totalusedSpin)
        setData(modifiedData);
        setTcount(response.data.data.count)
        Notiflix.Loading.remove();
      } catch (error) {
        console.log(error, "api does not work");
        Notiflix.Loading.remove();
      }
    };

    fetchData();
  }, [startDate, endDate, number, network, pagination]);


  const columns = [
    {
      field: 'id',
      headerName: 'SN',
      width: '20',
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'created_at_date',
      headerName: 'Spin Date',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },

    {
      field: 'created_at_time',
      headerName: 'Spin Time',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'mobile',
      headerName: 'Mobile Number',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'


    },
    {
      field: 'network',
      headerName: 'Network',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },


    {
      field: 'no_of_spins',
      headerName: 'Spin Used',
      flex: 1,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },





  ];

  return (
    <div className='myContainer'>
      <div className='innerContainer'>
        <div className='containerCard'>


          <div className="col-md-3">
            <Card
              title={"Spin Used"}
              number={usedSpin ? usedSpin : '0'}
              className='myCard indiviusalCard'
            />
          </div>



        </div>

        <div className='row'>
          <div className='filter'>
            <div className='filterByDate date_number'>
              <div>
                <div className='title'>
                  <h6>
                    Filter By Date:
                  </h6>
                </div>
                <div className='datePicker'>
                  <div>
                    <p className='m-0'>Start Date</p>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                    />
                  </div>
                  <div>
                    <p className='m-0'>End Date</p>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                      minDate={startDate}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column justify-content-between'>
                <div className='title'>
                  <h6>
                    Filter By Phone Number:
                  </h6>
                </div>
                <div className='mobilePicker'>

                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="mobile number" aria-label="Username" onChange={(e) => setNumber(e.target.value)} />

                  </div>
                </div>
              </div>
            </div>
            <div className='filterByNetwork py-3'>
              <div className='title'>
                <h6>Filter By Network:</h6>
              </div>
              <div className='networkPicker'>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="network" id="allNetwork" value="all" onChange={handleNetworkChange} checked={network === 'all'} />
                  <label className="form-check-label" htmlFor="allNetwork">All</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="network" id="mtnNetwork" value="mtn" onChange={handleNetworkChange} checked={network === 'mtn'} />
                  <label className="form-check-label" htmlFor="mtnNetwork">MTN</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="network" id="airtelNetwork" value="airtel" onChange={handleNetworkChange} checked={network === 'airtel'} />
                  <label className="form-check-label" htmlFor="airtelNetwork">Airtel</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="network" id="gloNetwork" value="glo" onChange={handleNetworkChange} checked={network === 'glo'} />
                  <label className="form-check-label" htmlFor="gloNetwork">Glo</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="network" id="etisalatNetwork" value="9mobile" onChange={handleNetworkChange} checked={network === '9mobile'} />
                  <label className="form-check-label" htmlFor="etisalatNetwork">9mobile</label>
                </div>
              </div>
            </div>

          </div>


        </div>

        <div className="datagrid containerDataGrid">
          <h5>Used Spins</h5>

          <div className="datagridBox">
            <Box sx={{ height: '500px', width: '100%' }}>
              <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pagination
                paginationMode='server'
                pageSize={10}
                rowCount={tcount}
                pageSizeOptions={[10]}
                rowsPerPageOptions={[10]}
                onPaginationModelChange={(e, f) => { console.log(e); setPagination({ ...pagination, page: e.page + 1, pageSize: e.pageSize }) }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}