import React, { useState } from 'react';
import { baseUrl } from './Constant';
import Notiflix from 'notiflix';
import axios from 'axios';

export default function SendSMS() {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (file) {
            const formData = new FormData();
            formData.append('file', file);  // Append the file to FormData

            try {
                const response = await axios.post(`${baseUrl}/user/sms-campaign/1`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Required to handle file uploads
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                });

                if (response) {
                    Notiflix.Notify.success('File successfully submitted!');
                }

            } catch (error) {
                Notiflix.Notify.failure(error?.response?.data?.message || error?.message);
            }
        } else {
            Notiflix.Notify.warning('No file selected');
        }
    };

    return (
        <div className="mc-main-container send-sms">
            <div>
                <h3>Send SMS</h3>
                <form onSubmit={handleSubmit}>
                    <div className='csv-file-input-wrapper'>
                        <label htmlFor="csvFile">Upload CSV File:</label>
                        <input
                            type="file"
                            id="csvFile"
                            accept=".csv"
                            onChange={handleFileChange}
                            required
                        />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}
