import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from './Component/Header';
import Sidebar from './Component/Sidebar';
import { MenuProvider } from './Context/HeaderContext';
import ProtectedRoute from './ProtectedRoute';
import { jwtDecode } from 'jwt-decode';

export default function Layout() {
    const { isSidebarHidden, isOutletFullWidth } = MenuProvider();
    const [userName, setUserName] = useState("");
    let token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (token) {
            console.log(token);
            const decoded = jwtDecode(token);
            setUserName(decoded.username);
        }
    }, [token]);

    useEffect(() => {
        if (userName === "backend" && location.pathname === "/") {
            navigate('/backend');
        }
        if(userName === "regulatory" && location.pathname === "/") {
            navigate("/regulatory");
        }
    }, [userName, navigate]);

    return (
        <>
            <Header />
            <div className={`main_container ${isOutletFullWidth ? 'outletFullWidth' : ''}`}>
                <div className={`sideBar ${isSidebarHidden ? 'sidebarHidden' : ''}`}>
                    <div className="sidebarWrapper">
                        <Sidebar />
                    </div>
                </div>
                <div className='Outlet'>
                    <div className="outletWrapper">
                        <ProtectedRoute>
                            <Outlet />
                        </ProtectedRoute>
                    </div>
                </div>
            </div>
        </>
    );
}
