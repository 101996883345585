import React from 'react'

export default function Card({ title, icon, number, className }) {
    return (
        <div className={`card mb-3 ${className}`}>
            <div className="cardOuter d-flex g-5">
                <div className="content">
                    <div className="title">
                        <h3>{title}</h3>
                    </div>
                    <div className="userData">{number}</div>
                </div>
            </div>
        </div>
    )
}
