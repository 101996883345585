import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { MdDashboard, MdSms } from "react-icons/md";
import { GiTargetPrize } from "react-icons/gi";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { LuFerrisWheel } from "react-icons/lu";
import { GiWallet } from "react-icons/gi";
import { HiMiniBanknotes } from "react-icons/hi2";
import { FaNairaSign } from "react-icons/fa6";
import { FaUserCheck, FaBan } from "react-icons/fa";
import { HiUserRemove } from "react-icons/hi";
import { MdSwitchAccount, MdAppBlocking } from "react-icons/md";
import { FaFileExcel } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom';
import { RiAccountPinCircleFill } from "react-icons/ri";
import { TbFidgetSpinner } from "react-icons/tb";
import { SiCampaignmonitor } from "react-icons/si";
export default function Sidebar() {

    const [activeNavItem, setActiveNavItem] = useState('');
    let token = localStorage.getItem("token");

    if (!token) {
        return <Navigate to='/login' />
    }

    const decoded = jwtDecode(token);
    const { username } = decoded;
    return (
        <div className="sidebar_content text-white">

            <Nav as="ul">

                <NavLink className="w-100 navlink" to='/'>
                    <Nav.Item as="li" className={activeNavItem === 'dashboard' ? 'active' : ''} onClick={() => setActiveNavItem('dashboard')}>
                        <div className='dashboard_logo'>
                            <MdDashboard />
                        </div>
                        <NavLink to='/'>DashBoard</NavLink>

                    </Nav.Item>
                </NavLink>
                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/add-prize'>
                    <Nav.Item as="li" className={activeNavItem === 'addprize' ? 'active' : ''} onClick={() => setActiveNavItem('addprize')}>
                        <div className='dashboard_logo'>
                            <GiTargetPrize />
                        </div>
                        <NavLink to='/add-prize'>Add Prize</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/add-scheme'>
                    <Nav.Item as="li" className={activeNavItem === 'Scheme' ? 'active' : ''} onClick={() => setActiveNavItem('Scheme')}>
                        <div className='dashboard_logo'>
                            <FaFileExcel />
                        </div>
                        <NavLink to='/add-scheme'>Prize List</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/registered-subscriber'>
                    <Nav.Item as="li" className={activeNavItem === 'Registered' ? 'active' : ''} onClick={() => setActiveNavItem('Registered')}>
                        <div className='dashboard_logo'>
                            <FaUserCheck />
                        </div>
                        <NavLink to='/registered-subscriber'>Registered Subscribers</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/inactive-subscriber'>
                    <Nav.Item as="li" className={activeNavItem === 'Inactive' ? 'active' : ''} onClick={() => setActiveNavItem('Inactive')}>
                        <div className='dashboard_logo'>
                            <HiUserRemove />
                        </div>
                        <NavLink to='/inactive-subscriber'>Inactive Subscribers</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/count-subscription-renewal-in-month'>
                    <Nav.Item as="li" className={activeNavItem === 'Count' ? 'active' : ''} onClick={() => setActiveNavItem('Count')}>
                        <div className='dashboard_logo'>
                            <MdSwitchAccount />
                        </div>
                        <NavLink to='/count-subscription-renewal-in-month' className='linkLineHeight'>Count: Subscription and Renewal</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                <NavLink className="w-100 navlink" to='/value-subscription-renewal-in-month'>
                    <Nav.Item as="li" className={activeNavItem === 'Value' ? 'active' : ''} onClick={() => setActiveNavItem('Value')}>
                        <div className='dashboard_logo'>
                            <MdOutlineAddToPhotos />
                        </div>
                        <NavLink to='/value-subscription-renewal-in-month' className='linkLineHeight'>Value: Subscription and Renewal</NavLink>
                    </Nav.Item>
                </NavLink>
                <NavLink className="w-100 navlink" to='/prize-won'>
                    <Nav.Item as="li" className={activeNavItem === 'Prize' ? 'active' : ''} onClick={() => setActiveNavItem('Prize')}>
                        <div className='dashboard_logo'>
                            <FaNairaSign />
                        </div>
                        <NavLink to='/prize-won'>Prize Won</NavLink>
                    </Nav.Item>
                </NavLink>
                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/prizes-in-wallet'>
                    <Nav.Item as="li" className={activeNavItem === 'Wallet' ? 'active' : ''} onClick={() => setActiveNavItem('Wallet')}>
                        <div className='dashboard_logo'>
                            <GiWallet />
                        </div>
                        <NavLink to='/prizes-in-wallet'>Wallet</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                <NavLink className="w-100 navlink" to='/withdrawals-status'>
                    <Nav.Item as="li" className={activeNavItem === 'Withdrawals' ? 'active' : ''} onClick={() => setActiveNavItem('Withdrawals')}>
                        <div className='dashboard_logo'>
                            <HiMiniBanknotes />
                        </div>
                        <NavLink to='/withdrawals-status'>Withdrawals Status</NavLink>
                    </Nav.Item>
                </NavLink>
                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/available-spins'>
                    <Nav.Item as="li" className={activeNavItem === 'Spins' ? 'active' : ''} onClick={() => setActiveNavItem('Spins')}>
                        <div className='dashboard_logo'>
                            <LuFerrisWheel />
                        </div>
                        <NavLink to='/available-spins'>Available Spins</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}
                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/used-spin'>
                    <Nav.Item as="li" className={activeNavItem === 'usedSpin' ? 'active' : ''} onClick={() => setActiveNavItem('usedSpin')}>
                        <div className='dashboard_logo'>
                            <TbFidgetSpinner />
                        </div>
                        <NavLink to='/used-spin'>Used Spins</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}
                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/available-spins'>
                    <Nav.Item as="li" className={activeNavItem === 'useraccounts' ? 'active' : ''} onClick={() => setActiveNavItem('useraccounts')}>
                        <div className='dashboard_logo'>
                            <RiAccountPinCircleFill />
                        </div>
                        <NavLink to='/user-accounts'>Users Account</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/all-users'>
                    <Nav.Item as="li" className={activeNavItem === 'allusers' ? 'active' : ''} onClick={() => setActiveNavItem('allusers')}>
                        <div className='dashboard_logo'>
                            <MdAppBlocking />
                        </div>
                        <NavLink to='/all-users'>Black List</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/send-sms'>
                    <Nav.Item as="li" className={activeNavItem === 'sendsms' ? 'active' : ''} onClick={() => setActiveNavItem('sendsms')}>
                        <div className='dashboard_logo'>
                            <MdSms />
                        </div>
                        <NavLink to='/send-sms'>Send SMS</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/suspend-user'>
                    <Nav.Item as="li" className={activeNavItem === 'suspenduser' ? 'active' : ''} onClick={() => setActiveNavItem('suspenduser')}>
                        <div className='dashboard_logo'>
                            <FaBan />
                        </div>
                        <NavLink to='/suspend-user'>Suspend User</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}
                {(username === "backend") ? (<NavLink className="w-100 navlink" to='/sms-campaign'>
                    <Nav.Item as="li" className={activeNavItem === 'smsCampaign' ? 'active' : ''} onClick={() => setActiveNavItem('smsCampaign')}>
                        <div className='dashboard_logo'>
                            <SiCampaignmonitor />
                        </div>
                        <NavLink to='/sms-campaign'>Sms Campaign</NavLink>
                    </Nav.Item>
                </NavLink>) : ""}

            </Nav>

        </div>
    );
}
