import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { baseUrl } from './Constant';

export default function AddPrize() {
    let token = localStorage.getItem('token')
    const initialPrizesState = Array(6).fill('');
    const [prizes, setPrizes] = useState(initialPrizesState);
    const [prizeData, setPrizeData] = useState(['']);

    const handleInput = (index, value) => {
        const updatedPrizes = [...prizes];
        updatedPrizes[index] = value;
        setPrizes(updatedPrizes);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formattedPrizes = prizes.map((prize) => ({ prizes: prize }));
        console.log('Formatted Prizes:', formattedPrizes);
        axios.post(`${baseUrl}/user/add_prize`, {
            valueToPush: formattedPrizes
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log('API Response:', response.data);
                fetchData();
                resetForm();
            })
            .catch((error) => {
                console.error('Error sending data to API:', error);

            });
        resetForm();
        fetchData();
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user/all_prizes_dashboard`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPrizeData(response.data.result);
            console.log(response.data.result, "response responseresponse");
        } catch (error) {
            console.error('Error fetching prizes:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
   
    const resetForm = () => {
        setPrizes(initialPrizesState);
    };

   

    return (
        <>
            <div className="addPrize">
                <div className="addPrize_folder">
                    <h4 className="mb-3">Add Prizes</h4>
                    <Form onSubmit={handleSubmit}>
                        {prizes.map((prize, index) => (
                            <Form.Group as={Row} className="mb-3" controlId={`formPlaintextPrize${index}`} key={index}>
                                <Form.Label column md="2">
                                    *Prize {index + 1}
                                </Form.Label>
                                <Col md="10">
                                    <Form.Control
                                        type="text"
                                        placeholder={`Enter Prize ${index + 1}`}
                                        value={prize}
                                        required
                                        onChange={(e) => handleInput(index, e.target.value)}
                                    />
                                </Col>
                            </Form.Group>
                        ))}
                        <Button variant="primary" type="submit" className="addPrize_Btn">
                            Submit
                        </Button>
                    </Form>
                </div>

            </div>
            <div className='activePrizeTable'>
                <div className='activePrizes'>
                    <h4 className="mb-3">Active Prizes list</h4>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Sno</th>
                                <th scope="col">Prize Id</th>
                                <th scope="col">Prizes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                prizeData && prizeData.map((item, i) => (
                                    <tr key={i} className='prizetable'>
                                        <th scope="row">{i + 1}</th>
                                        <th >{item.allocating_prize_id}</th>
                                        <th>N {item.prizes}</th>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
