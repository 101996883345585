import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { baseUrl } from './Constant';

export default function Login() {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaValue) {
            toast.error('Please complete the reCAPTCHA.');
            return;
        }

        setName(name);
        setPassword(password);

        await axios.post(`${baseUrl}/user/panel_login`, {
            email: name,
            password: password,
            recaptchaToken: captchaValue
        }).then((response) => {
            console.log(response)
            if (response.data.success) {
                localStorage.setItem('token', response.data.accessToken)
                toast.success(response.data.message)
                setTimeout(() => {
                    navigate('/')
                }, 1500);

            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error('An error occurred. Please try again.');
        });
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div className="loginParent bg-dark bg-gradient">
            <Toaster />
            <div id="main_form">
                <form className="login-form" onSubmit={handleSubmit}>
                    <h1 className='text-center title'>Login</h1>
                    <div className="form-group">
                        <label>User Id</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="User Id"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <p />

                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            placeholder="User Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <p />

                    <div className="form-group">
                        <ReCAPTCHA
                            sitekey="6Lc6ifEpAAAAAKlp8uupUM8NLm2vJjE5AmOf4sqt" // replace with your site key
                            onChange={handleCaptchaChange}
                        />
                    </div>
                    <p />

                    <div className='text-center'>
                        <Button type="submit" variant="primary" className='w-100'>
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
