import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { baseUrl } from './Constant';
import toast, { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import Notiflix from 'notiflix';

export default function AddSchemeList() {
    const [file, setFile] = useState(null);
    const [updatedFile, setUpdatedFile] = useState(null)
    const [msg, setMsg] = useState('')
    const fileInputRef = useRef(null);
    const [data, setData] = useState('')

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
        setMsg("")
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!file) {
            console.log("No file selected.");
            setMsg("No file selected")
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
      
        axios.post(`${baseUrl}/user/upload-excel?`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                toast.success(response.data.message)
                fileInputRef.current.value = '';

                getCsv()
               
            })
            .catch((error) => {
                fileInputRef.current.value = '';
                toast.error(error.response.data.message)
                
            });
    };


    const handleUpdateFileSubmit = (event) => {
        event.preventDefault();

        if (!updatedFile) {
            console.log("No file selected.");
            setMsg("No file selected")
            return;
        }

        const formData = new FormData();
        formData.append("file", updatedFile);

        Notiflix.Loading.pulse();

        axios.put(`${baseUrl}/user/update-csv-file-data?`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                Notiflix.Loading.remove();
                toast.success(response.data.message)
                fileInputRef.current.value = '';

                getCsv()
            })
            .catch((error) => {
                Notiflix.Loading.remove();
                fileInputRef.current.value = '';
                toast.error(error.response.data.message)
            });


    };

    const handleUpdateFileUpload = (event) => {
        setUpdatedFile(event.target.files[0]);
        setMsg("")
    };
    function CustomToolbar() {
        return (

            <GridToolbarExport csvOptions={{
                fileName: `Scheme List ${moment().format('DD-MM-YYYY')}`,
            }} />

        );
    }

    const getCsv = async () => {
        Notiflix.Loading.pulse();
        try {
            const response = await axios.get(`${baseUrl}/user/get-csv-file-data`);
            const modifiedData = response.data.data.map((item, i) => {
                return {
                    ...item,
                    id: i + 1,
                    created: item.created_at.split("T")[0]
                }
            });

            setData(modifiedData)
            console.log(modifiedData, "fetch csv data")
            Notiflix.Loading.remove();
        } catch (error) {
            console.error('Error fetching on csv file:', error);
            Notiflix.Loading.remove();
        }
    }

    useEffect(() => {
        getCsv();
    }, [])

    const columns = [
        {
            field: 'id',
            headerName: 'SN',
            width: '20',
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'win_spinCount',
            headerName: 'Spin Number',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'prize_id',
            headerName: 'Prize Id',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'spin_status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },

    ];
    return (
        <div className='addmyScheme_container'>
            <Toaster />
            <div className='addmyScheme'>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Update Current Scheme List" value="1" />
                            <Tab label="Add Scheme List" value="2" />


                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div>

                            <Form onSubmit={handleUpdateFileSubmit}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className='label'>*Please upload a updated csv file.</Form.Label>
                                    <Form.Control type="file" onChange={handleUpdateFileUpload} ref={fileInputRef} accept=".csv" />
                                </Form.Group>
                                <p className='text-center m-0'>{msg}</p>
                                <Button type="submit" variant="primary">Upload Edit Csv</Button>
                            </Form>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <div>

                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className='label'>*Please upload a csv file.</Form.Label>
                                    <Form.Control type="file" onChange={handleFileUpload} ref={fileInputRef} accept=".csv" />
                                </Form.Group>
                                <p className='text-center m-0'>{msg}</p>
                                <Button type="submit" variant="primary">Upload Csv</Button>
                            </Form>
                        </div>
                    </TabPanel>


                </TabContext>
            </div>

            <div className='viewCsv mt-5'>
                <div className="datagrid  containerDataGrid w-100">
                    <h5>Scheme List</h5>

                    <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 100,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                rowsPerPageOptions={[10]}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    </div>
                </div>


            </div>
        </div>
    );
}
